import React, { Fragment } from "react"

import { getHasValidatedLastName } from "../Auth/services/getHasValidatedLastNameValue"
import { navigateToVerifyLastNamePage } from "../Auth/services/navigateToVerifyLastNamePage"

const PrivateRoute = ({ isPrivate, children, location }) => {
  const urlSearchParams = new URLSearchParams(location.search)
  const zendeskId = urlSearchParams.get("z") || ""
  const hasValidatedLastName = getHasValidatedLastName()

  if (isPrivate && !hasValidatedLastName) {
    navigateToVerifyLastNamePage({ zendeskId: zendeskId })
  }

  return <Fragment>{children}</Fragment>
}

export default PrivateRoute
