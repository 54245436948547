import React from "react"
import classNames from "classnames"

import UploadDropzoneBody from "./UploadDropzoneBody"
import UploadDropzoneLabel from "./UploadDropzoneLabel"

import styles from "../utils/upload.module.scss"

const UploadDropzone = ({
  label,
  icon,
  fileInputRef,
  handleFileChooser,
  handleFileRead,
  notifications = [],
}) => {
  return (
    <div className={classNames("container mt-1", styles["dropzone"])}>
      <input
        type="file"
        className={styles["dropzone__input"]}
        ref={fileInputRef}
        onChange={handleFileRead}
        multiple
        accept="image/*, .pdf"
        value=""
      />

      {notifications.length > 0 && notifications.map((item) => item)}
      <div
        className={classNames(styles["dropzone__container"])}
        onClick={handleFileChooser}
      >
        <UploadDropzoneBody icon={icon} label={label} />
        <UploadDropzoneLabel label={label} />
      </div>
    </div>
  )
}

export default UploadDropzone
